$containerWidth: 1140px !default;
$beige: #efedeb !default;
$blue: #0e3e67 !default;
$lightBlue: #e7ebef !default;
$darkGrey: #777777 !default;
$green: #0d8577 !default;
$lightGreen: #e6f3f2 !default;
$lightGrey: #939393 !default;
$mintCream: #f4fafa !default;
$offWhite: #eeeeee;
$orange: #cc6600 !default;
$lightOrange: #f9e3d0 !default;
$accessibleOrange: #f58b33 !default;
$white: #ffffff !default;
$goldFontColor: #f2b01e !default;
$mainFont: Roboto, sans-serif !default;
$fontAwesomeFont: "Font Awesome 5 Free" !default;
$offBlack: #212222 !default;

body {
    font-family: $mainFont;
}

a {
    color: $orange;
    &:hover {
        color: $green;
        text-decoration: none;
    }
}

h1 {
    color: $blue;
    font-size: 40px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1em;
    letter-spacing: 5px;
}

h2 {
    color: $green;
    font-size: 30px;
    font-weight: bold;
    text-transform: capitalize;
}

h3 {
    color: $green;
    font-weight: bold;
    font-size: 25px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

h4 {
    color: $blue;
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 20px;
}

h5 {
    color: $blue;
    font-size: 20px;
    font-weight: normal;
    text-transform: capitalize;
}

h6 {
    color: $lightGrey;
    font-size: 20px;
    font-weight: normal;
    text-transform: uppercase;
}

.heading-7 {
    color: $lightGrey;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1.5px;
}

.heading-8 {
    color: $orange;
    font-size: 20px;
    font-weight: bold;
}

.action-buttons {

    .btn {
        margin: 30px 15px;
        fill: $white;
        color: $white;
        background-color: $blue;
        border-color: $blue;
        border-style: solid;
        border-radius: 50px;
        padding: 10px 50px;
        font-family: $mainFont;
        font-weight: 400;
        font-size: 16px;
        transition: all .3s;
        &:hover {
            color: $blue !important;
            border-color: $blue !important;
            background-color: $white !important;
        }
    }
}

.alert-popup {
    background-color: $green;
    color: $white;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid $green;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.03);
    width: 300px;
    box-sizing: border-box;
    margin-top: 90px !important;
    text-align: center;
    &.info {
        background-color: $blue;
        border: 1px solid $blue;
    }
    &.error {
        background-color: $orange;
        border: 1px solid $orange;
    }
    &.relative {
        position: absolute;
        text-align: left;
        h1 {
            line-height: 1.2em;
        }
    }

    a {
        color: $lightGrey;
        &:hover {
            color: $white;
        }
    }

    h1 {
        color: $white;
        text-transform: none;
        font-size: 20px;
        letter-spacing: unset;
    }

    .alert-close {
        margin-left: 20px;
        border: none;
        background-color: transparent;
        cursor: pointer;
        color: $white;
    }

    .fa-check-circle, .fa-info-circle, .fa-exclamation-circle {
        margin-right: 20px;
        font-size: 30px;
    }
}

.approach-experience {
    position: relative;
    background-color: $mintCream;
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 100% auto;
    padding: 70px 10px;

    h1 {
        text-align: left;
    }

    .testimonial-carousel {
        background-color: transparent;
        padding: 0;
    }
}

.background-overlay {
    background-color: rgba(14,62,103,0.85);
    opacity: 0.8;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    height: 726px;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
}

.banner-container {
    height: 400px;

    .banner-header {
        background-color: $green;
        padding: 30px;
        width: 445px;
        text-align: center;
        margin-left: 30px;

        h1 {
            color: $white;
            font-size: 40px;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: unset;
            margin: 0;
        }
    }

    .container {
        position: relative;
        top: calc(100% - 55px);
    }
}

.blog-categories, .blog-tags {
    color: $blue;
    margin: 10px 0;
}

.blog-category {
    margin: 70px 10px -50px;
    font-size: 20px;
    font-weight: 500;

    .category-header {
        color: $blue;
    }

    .category-name {
        color: $green;
    }
}

.blog-comment-container {
    border: 1px solid $offWhite;
    border-radius: 4px;
    margin-bottom: 30px;


    .blog-comment {
        background-color: $offWhite;
        padding: 10px;
        border-radius: 0 0 4px 4px;
    }

    .blog-comment-date {
        text-align: right;
        font-size: 14px;
        color: $darkGrey;
    }

    .blog-comment-name {
        padding: 10px;
        color: $green;
    }
}

.blog-footer {
    font-size: 18px;
    font-weight: 500;
    padding-right: 15px;
    border-right: 15px solid $green;
    margin-top: 50px;
}

.blog-header {
    margin-top: 70px;
    color: $blue;
    font-size: 32px;
    font-weight: 700;
}

.blog-hr {
    margin: 30px 0;
}

.blog-list-post {
    margin: 30px 0 5px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.15);
    border-radius: 3px;
    max-width: calc(33% - 20px);
    display: inline-block;
    text-align: left;

    .blog-list-image {
        width: 100%;
        border-radius: 3px 3px 0 0;
        margin-top: -28px;
    }

    .dot {
        height: 3px;
        width: 3px;
        background-color: $lightGrey;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        margin: 0 5px;
    }

    .image-tag {
        background-color: $green;
        color: $white;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        position: relative;
        padding: 5px 15px;
        border-radius: 14px;
        top: 20px;
        width: fit-content;
        float: right;
        right: 20px;
    }

    .post-meta {
        color: $lightGrey;
        font-size: 12px;
        font-weight: 400;
        padding: 20px 30px 15px;
        border-top: 1px solid $lightGrey;
        margin-top: 25px;
        height: 60px;
    }

    .post-summary {
        font-size: 14px;
        font-weight: 400;
        color: $darkGrey;
        padding: 0 30px 5px;
    }

    .post-title {
        font-size: 21px;
        font-weight: 400;
        color: $blue;
        padding: 15px 30px 20px;
        line-height: 28px;
    }

    .read-more {
        text-transform: uppercase;
        color: $blue;
        font-size: 12px;
        font-weight: 400;
        margin: 0 30px;
    }

    .user-image {
        width: 60px;
        border-radius: 30px;
        margin: -30px auto auto 30px;
    }
}

.blog-meta {
    margin-top: 25px;

    .blog-meta-left {
        float: left;

        img {
            width: 50px;
            border-radius: 25px;
            float: left;
            margin-right: 20px;
        }

        .blog-meta-author {
            color: $blue;
        }

        .blog-meta-date {
            color: $darkGrey;
        }

        .blog-meta-details {
            display: inline-block;
        }
    }

    .blog-meta-right {
        float: right;
        color: $darkGrey;
    }
}

blockquote {
    background: #f9f9f9;
    border-left: 10px solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
    quotes: "“" "”";
}

blockquote:before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
}

blockquote p {
    display: inline;
}

.blog-post {
    font-weight: 300;
    font-size: 18px;

    h1 {
        font-size: 20px;
        font-weight: 500;
        text-transform: unset;
        letter-spacing: unset;
        line-height: 1.5em;
    }

    h2 {
        font-size: 20px;
        font-weight: 500;
        color: $blue;
    }

    h3 {
        font-size: 18px;
        font-weight: 500;
        color: $blue;
        margin: 0 0 30px 30px;
        text-transform: unset;
    }

    img {
        max-width: calc(100% - 50px);
        margin: auto;
        display: block;
    }

    p {
        margin: 20px 0;
    }
}

.blog-summary {
    font-size: 24px;
    font-weight: 500;
    color: $blue;
    margin: 25px;
}

.blue-link {
    color: $blue;
    &:hover {
        color: $blue;
    }
}

.btn {
    &.disabled, &.disabled:hover {
        background-color: $lightGrey !important;
        border-color: $lightGrey !important;
        color: $white !important;
    }
}

.btn-primary {
    fill: $orange;
    color: $white;
    background-color: $orange;
    border: 3px solid $orange;
    border-radius: 50px;
    padding: 10px 50px;
    font-family: $mainFont;
    font-weight: 400;
    font-size: 16px;
    margin-top: 15px;
    transition: all .3s;
    &:hover, &:active, &:focus {
        color: $orange !important;
        background-color: transparent !important;
        border-color: $orange !important;
    }
}

.btn-primary-inverse {
    fill: $white;
    color: $orange;
    background-color: $white;
    border: 3px solid $white;
    border-radius: 50px;
    padding: 3px 15px;
    font-family: $mainFont;
    font-weight: 400;
    font-size: 16px;
    margin-top: 15px;
    transition: all .3s;
    &:hover, &:active, &:focus {
        color: $white !important;
        background-color: transparent !important;
        border-color: $white !important;
    }
}

.carousel {
    background-color: $mintCream;
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 100% auto;
    padding: 70px 10px;
    &.our-approach-carousel, &.coaching-benefits-carousel {
        background-color: $white;

        .header-container {
            margin-bottom: 0;
            padding: 0 0 20px;
        }

        .our-approach-subheader {
            font-size: 20px;
            line-height: 26px;
            font-weight: 300;
            margin-top: 20px;
            text-align: left;
        }

        .rec-dot {
            background-color: $lightGrey;
            &:hover, &:focus {
                box-shadow: none !important;
            }
        }

        .rec-dot_active {
            background-color: $blue;
        }

        .slide {
            padding: 0;
            min-height: unset;

            img {
                width: 125px;
                max-width: 125px;
                margin: auto;
                display: block;
            }

            .highlight-details-container {
                width: 100%;
                text-align: center;

                .highlight-details-detail {
                    line-height: 25px;
                    font-size: 16px;
                    font-weight: 300;
                }


                .highlight-details-header {
                    font-weight: 600;
                    font-size: 20px;
                    margin: 40px 0 10px;
                    min-height: 60px;
                    letter-spacing: 1.3px;
                    line-height: 30px;
                }
            }
        }
    }

    &.coaching-benefits-carousel {
        background-color: $green;

        h1, h4 {
            color: $white;
        }

        .rec-arrow {
            color: $white;
        }

        .rec-dot:not(.rec-dot_active) {
            background-color: $white;
        }

        .slide {
            background-color: $green;

            img {
                width: 64px;
                max-width: 64px;
                background-color: $white;
                border-radius: 32px;
            }

            .highlight-details-detail {
                color: $white;
            }
        }
    }

    .button-container {
        margin-top: 20px;
    }

    .container {
        max-width: $containerWidth;
        margin: auto;
    }

    .header-container {
        margin-bottom: 40px;
        padding: 0;

        h1 {
            text-align: left;
        }
    }

    &.course-carousel {

        .header-container {

            h1 {
                text-align: center;
            }
        }
    }

    .rec-arrow {
        box-shadow: none;
        background-color: transparent;
        color: $lightGrey;
        height: 22px;
        line-height: 22px;
        font-size: 22px;
        width: 22px;
        min-width: 22px;
        &:hover, &:focus {
            box-shadow: none !important;
            background-color: transparent !important;
            color: $lightGrey !important;
        }
    }

    .rec-dot {
        background-color: $white;
        box-shadow: none;

        &:hover, &:focus {
            box-shadow: none !important;
        }
    }

    .rec-dot_active {
        background-color: $blue;
    }

    .slide {
        padding: 20px;
        background: $white;
        min-height: 450px;
        width: 100%;
        margin-right: 10px;

        img {
            width: 100%;
        }

        .info-container {
            padding: 10px 0;

            .caption {
                font-size: 18px;
                font-weight: 400;
                color: $blue;
                line-height: 24px;
                margin-bottom: 10px;
            }

            .header-info {
                height: 130px;
            }

            .slider-body {
                font-family: $mainFont;
                font-weight: 400;
                color: $offBlack;
                font-size: 16px;
            }

            .slider-header {
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: 1.5px;
                color: $lightGrey;
                margin-bottom: 10px;
            }

            .slider-link {
                margin-top: 10px;
            }
        }
    }

    .course-slide {
        min-height: 410px;

        .info-container {

            .header-info {
                height: 80px;
            }

            .course-dates {
                min-height: 48px;
            }
        }
    }

    .sub-header {
        color: $offBlack;
        font-size: 22px;
        font-weight: 300;
        line-height: 27px;
    }
}

.cart-count {
    background-color: $orange;
    color: $white;
    font-size: 10px;
    font-weight: 900;
    width: 17px;
    text-align: center;
    border-radius: 8px;
    position: relative;
    left: -13px;
    top: -13px;
    display: inline-block;
    border: 1px solid $white;
    padding-left: 1px;
}

.cart-item {
    margin: 10px 0;
    height: 100px;

    img {
        height: 100%;
        max-width: 135px;
        object-fit: cover;
        float: left;
        margin-right: 10px;
    }

    .item-details {
        text-align: right;
        width: calc(100% - 176px);
        margin-left: 145px;
    }

    .item-name {
        font-weight: 500;
        width: calc(100% - 176px);
        float: right;
        margin-right: 10px;
    }

    .number-sessions {
        color: $blue;
        font-weight: 400;
    }

    .remove-item {
        float: right;

        i {
            color: $white;
            background-color: $orange;
            border-radius: 11px;
            width: 21px;
            height: 20px;
            padding: 2px 0 0 5px;
            font-size: 16px;
            cursor: pointer;
            &:hover {
                background-color: $green;
            }
        }
    }
}

.cart-item-container {
    border: 1px solid $orange;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 20px;
}

.cart-total {
    font-weight: 500;
    text-align: right;
    margin-right: 31px;
}

.checkout-page-ordered-item {
    .start-date {
        color: $blue;
        font-weight: 500;
        background-color: $offWhite;
        padding: 5px 5px 0;
        margin: 0 0 10px;
        border-radius: 4px;
        border: 1px solid $lightGrey;
        &.single-start-date {
            background-color: $lightGreen;
            border: 1px solid $green;
            padding: 12px;

            .detail {
                color: $green !important;
            }
        }
        &.date-selected {
            background-color: $lightGreen;
            border: 1px solid $green;
        }

        select {
            border: 3px solid $orange;
            color: $orange;
        }
    }
}

.coaching-call-to-action {
    background-color: $blue;
    padding: 40px 20px;
    color: $white;
    position: relative;

    h1 {
        text-align: center;
        color: $white;
    }

    .call-to-action {
        margin: 20px 0;
    }

    .coaching-register-interest {
        text-align: center;

        a:hover {
            background-color: $white !important;
        }
    }
}

.coaching-cart-item {
    background-color: $offWhite;
}

.coaching-checkout-item {
    background-color: $offWhite;
    padding: 10px;

    .start-date {
        background-color: $white;
    }
}

.coaching-faqs {
    background-color: $green;
    padding: 40px;
    position: relative;

    h4 {
        text-align: center;
        color: $white;
        font-size: 40px;
    }

    .faq-answer {
        color: $white;
    }

    .faq-question {
        color: $white !important;
    }

    .faq-section {
        border-bottom: 1px solid $white;
    }
}

.coaching-options-container {
    background-color: $offWhite;
    position: relative;
    padding: 40px 0;

    h1 {
        text-align: center;
    }

    .coaching-options {
        text-align: center;
        margin: 20px 0 40px;
    }

    .coaching-option {
        text-align: center;

        .coaching-option-image {
            width: 90%;
            height: 324px;
            object-fit: cover;
        }

        .coaching-option-text {
            margin-top: 20px;
        }
    }
}

.coaching-package-container {
    text-align: left;
    background-color: $white;
    margin: 0 5px;
    padding: 20px;
    max-width: calc(33% - 10px);
}

.coaching-packages {
    background-color: $orange;
    padding: 40px 20px;
    color: $white;
    position: relative;

    h1 {
        text-align: center;
        color: $white;
    }

    .coaching-package {
        margin: 20px 0 40px;
    }
}

.coaching-testimonial {
    background-color: $blue;
    padding: 40px 20px;
    color: $white;
    position: relative;

    .coaching-testimonial-image {
        height: 160px;
    }

    .testimonial-quotation {
        margin: auto;
    }
}

.contact-page {
    margin-top: 70px;
}

.hero-header {
    background-position: top center;
    background-size: cover;
    height: 600px;
    width: 100%;
    &.home-hero-header, &.organisations-hero-header {
        position: fixed;

        .background-overlay {
            height: 100%;
        }
    }

    h1 {
        color: $white;
    }
}

.header-row {
    max-width: $containerWidth;
    margin: auto;
    align-items: center;
    height: 600px;
}

.comment-button {
    margin: 50px 0 0 30px;
}

.contact-details-container {
    padding: 50px;
    margin-top: 70px;
    background-color: $blue;
    color: $white;
    font-weight: 500;

    .contact-icon {
        margin-top: 15px;

        i {
            font-size: 29px;
            color: rgba(255, 255, 255, 0.39);
            width: 40px;
        }

        span {
            vertical-align: super;
        }
    }
}

.contact-form {
    margin-top: 70px;

    .contact-submit {
        width: 100%;
        text-align: right;
    }

    .form-group {
        width: 100%;
    }

    .loader-container {
        width: 100%;
    }
}

.course-calendar {
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;

    .chart-row {
        display: grid;
        &.chart-lines {
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: transparent;

            > span {
                border-right: 10px solid $white;
                background-color: $lightGreen;
                &:first-child {
                    background-color: transparent;
                    width: 170px;
                    border-right: 0;
                }
            }
        }
        &.chart-period {
            color: $white;
            border-bottom: 5px solid $white;
            text-transform: uppercase;

            > span {
                text-align: center;
                align-self: center;
                font-weight: bold;
                padding: 15px 0;
                background-color: $green;
                border-right: 10px solid $white;
            }
        }

        .chart-row-bars {
            list-style: none;
            display: block;
            padding: 15px 0;
            margin: 0;

            li {
                font-weight: 450;
                text-align: left;
                min-height: 15px;
                background-color: $lightOrange;
                border-left: 15px solid $orange;
                padding: 5px 15px;
                color: $offBlack;
                overflow: hidden;
                position: relative;
            }
        }

        .chart-row-item {
            background-color: $white;
            border-top: 0;
            border-left: 0;
            padding: 20px 10px 20px 0;
            text-align: left;
            width: 170px;
            isolation: isolate;

            a {
                color: $green;
                cursor: pointer;
                &:hover {
                    color: $orange;
                }
            }
        }

        .gantt-bar-container {
            position: absolute;
            margin-top: 20px;
            width: 100%;

            .gantt-bar {
                position: absolute;
                background-color: $lightOrange;
                height: 50px;
                padding-top: 15px;
                padding-left: 15px;
                border-left: 15px solid $orange;
            }

            .gantt-label {
                position: absolute;
                line-height: 1em;
                width: 130px;
                margin-top: 19px;
            }
        }
    }
}

h1.course-calendar-header {
    margin: 70px 0 40px;
    text-align: center;
}

.course-experience-image-container {
    margin-top: 70px;

    .course-experience-image {
        width: 100%;
        background-position: top center;
        background-size: cover;
        position: relative;
        height: 360px;

        .background-overlay {
            height: 100%;
        }
    }

    .course-experience-testimonial {
        color: $white;
        position: relative;
        padding: 40px;
    }
}

.course-header-left-container {
    height: auto;
    padding: 15px 15px 0;

    .course-header-left {
        background-color: $white;
        padding: 5px 0;
        height: auto;
        line-height: 27px;

        h3 {
            color: $blue;
            font-size: 24px;
            font-weight: 500;
            text-transform: unset;
            letter-spacing: unset;
        }

        .course-header-left-h1 {
            font-size: 36px;
            font-weight: 700;
            color: $green;
            text-transform: unset;
            letter-spacing: 1.2px;
            margin-bottom: 15px;
        }

        .header-details {
            min-height: unset;
        }

        .text-block-container {
            margin: 0;

            .text-block {
                h1 {
                    color: $offBlack;
                    font-size: 18px;
                    font-weight: 400;
                    text-transform: unset;
                    letter-spacing: unset;
                    line-height: 27px;
                }
            }
        }
    }
}

.course-hero-header {
    height: auto;

    .background-overlay {
        height: 676px;
        background-color: $blue;
        opacity: 1;
    }

    .course-header-row {
        flex-direction: row-reverse;

        .course-header-right {
            margin: 20px 0;

            h2 {
                color: $accessibleOrange;
                text-transform: none;
            }

            .btn-primary {
                fill: $white;
                color: $blue;
                background-color: $white !important;
                border: 3px solid $white;
                border-radius: 8px;
                padding: 10px 50px;
                font-family: Roboto, sans-serif;
                font-weight: 700;
                font-size: 16px;
                margin-top: 15px;
                transition: all 0.3s;

                &:hover, &:active, &:focus {
                    color: $white !important;
                    background-color: transparent !important;
                    border-color: $white !important;
                }
            }
        }

        .course-image {
            width: auto;
            object-fit: cover;
            max-height: 600px;
        }

        .course-top-right-info-container {
            background-color: $white;
            padding: 20px;
            margin: 20px 0;
            height: auto;

            .button-container {
                text-align: center;

                .btn-primary {
                    font-size: 15px;
                    padding: 10px 20px;
                    margin: 15px 20px 0;
                }
            }

            .course-header-right-h1 {
                color: $offBlack;
                font-size: 25px;
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: 2.5px;
                padding-bottom: 10px;
            }

            .course-information {
                column-width: 15em;

                .course-top-right-section {
                    display: inline-block;
                    color: $offBlack;
                    padding: 2px 0;
                    text-indent: -15px;
                    margin-left: 30px;

                    .fa {
                        color: $orange;
                        font-size: 14px;
                        font-weight: 900;
                        margin-right: -5px;
                        width: 20px;
                    }
                }
            }

            .start-dates {
                padding: 4px 0;

                .course-top-right-section {
                    color: $offBlack;
                    text-indent: -15px;
                    margin-left: 30px;

                    .fa {
                        color: $orange;
                        font-size: 14px;
                        font-weight: 900;
                        margin-right: -5px;
                        width: 20px;
                    }
                }
            }
        }
    }
}

.course-information-section {
    height: auto;
    background-color: $green;

    .course-header-row {
        height: auto;

        .course-top-right-info-container {
            background-color: $white;
            height: auto;
            margin: 20px 0;
            padding: 20px;

            .button-container {
                text-align: center;

                .btn-primary {
                    font-size: 15px;
                    margin: 15px 20px 0;
                    padding: 10px 20px;
                }
            }

            .course-header-right-h1 {
                color: #212222;
                font-size: 25px;
                font-weight: 500;
                letter-spacing: 2.5px;
                padding-bottom: 10px;
                text-transform: uppercase;
            }

            .course-information {
                -webkit-column-width: 15em;
                column-width: 15em;

                .course-top-right-section {
                    color: #212222;
                    display: inline-block;
                    margin-left: 30px;
                    padding: 2px 0;
                    text-indent: -15px;

                    .fa {
                        color: #c60;
                        font-size: 14px;
                        font-weight: 900;
                        margin-right: -5px;
                        width: 20px;
                    }
                }
            }
        }
    }
}

.course-main-section {
    padding: 0 15px 15px;

    .course-inclusions {
        background-color: $green;
        color: $white;
        padding: 20px;
        margin-top: 20px;

        h1 {
            color: $white;
        }

        .course-inclusion {
            text-indent: -15px;
            margin: 5px 0 5px 30px;

            a {
                color: $white;
                &:hover {
                    color: $orange;
                }
            }

            .fa {
                font-size: 20px;
                font-weight: 900;
                margin-right: -5px;
                width: 20px;
                vertical-align: text-bottom;
            }
        }
    }

    .course-main-section-left {
        padding: 30px 15px 15px;
        background-color: $white;

        .discount {
            font-style: italic;
            margin-top: 20px;
        }

        .image-section {
            margin: 20px 0;

            div {
                width: calc(100% - 110px);
                float: right;
                margin-top: 10px;
            }

            .image-section-header {
                font-size: 18px;
                font-weight: 500;
                color: $blue;

                h4 {
                    margin-bottom: 0;
                    padding-top: 6px;
                }
            }

            img {
                width: 85px;
                margin-right: 25px;
                float: left;
            }
        }

        .learning-outcomes {

            h2 {
                font-size: 18px;
                font-weight: 500;
                color: $offBlack;
                margin: 20px 0 25px;
            }

            .outcomes {
                column-width: 20em;
                font-weight: 300;

                .learning-outcome {
                    margin-bottom: 0;
                    padding-left: 20px;
                }
            }
        }

        .text-block-container {
            margin: 20px 0 0;

            .text-block {
                font-size: 16px;
                font-weight: 400;

                h1 {
                    font-size: 18px;
                    font-weight: 400;
                    color: $blue;
                    text-transform: unset;
                    letter-spacing: 0.5px;
                    line-height: 27px;
                }
            }
        }
    }

    .find-out-more {
        background-color: $blue;
        color: $white;
        padding: 20px;
        margin-top: 20px;
    }

    .sign-up-and-save {
        font-weight: 500;
        margin-top: 20px;

        h1 {
            font-size: 20px;
            font-weight: 400;
            color: $orange;
            text-transform: unset;
            letter-spacing: unset;
        }

        i {
            color: $white;
            font-size: 50px;
            width: 60px;
            text-align: center;
        }

        .icon-background {
            background-color: $orange;
            border-radius: 45px;
            width: 90px;
            padding: 20px;
            float: left;
            margin-right: 30px;
        }
    }
}

.course-mentor-carousel {
    background-color: $beige;
    padding: 20px;

    .header-container {
        margin-bottom: 0;

        .course-mentor-header {
            color: $blue;
            font-size: 40px;
            font-weight: 500;
            text-transform: uppercase;
            margin-bottom: 20px;
            margin-top: 10px;
        }
    }

    .rec-arrow {
        color: $white;
    }

    .slide {
        height: 460px;

        img {
            width: 100px;
            height: 100px;
            border-radius: 50px;
            object-fit: cover;
        }

        .info-container {
            hr {
                border-top: 2px solid $blue;
                width: 33%;
            }

            .caption {
                font-size: 20px;
            }

            .header-info {
                height: 70px;
            }

            .slider-body {
                font-weight: 300;
                text-align: left;
            }
        }
    }
}

.course-module-carousel {
    background-color: $green;
    padding: 20px;

    .header-container {
        margin-bottom: 0;

        .course-module-header {
            color: $white;
            font-size: 40px;
            font-weight: 500;
            text-transform: uppercase;
            margin-bottom: 10px;
        }
    }

    .slide {
        img {
            height: 145px;
            width: auto;
        }

        .info-container {
            font-size: 16px;
            font-weight: 300;

            .caption {
                font-weight: 500;
            }

            .slider-body {
                font-size: 14px;
                font-style: italic;
            }

            .slider-footer {
                font-size: 14px;
                font-weight: 500;
                color: $lightGrey;
                text-transform: uppercase;
                margin-top: 28px;
            }
        }
    }
}

.course-testimonial-carousel {
    background-color: $blue;
    padding: 20px;

    .header-container {
        margin-bottom: 0;

        .course-testimonials-header {
            color: $lightGrey;
            font-size: 40px;
            font-weight: 500;
            text-transform: uppercase;
        }
    }

    .rec-dot_active {
        background-color: $lightGrey;
    }

    .slide {
        background-color: $blue;
        color: $white;
        font-size: 20px;
        font-weight: 300;
        min-height: 180px;

        .course-testimonial-header-info {
            height: 60px;
        }

        .course-testimonial-caption {
            display: inline-block;
            font-size: 17px;
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: 2.5px;
            color: $lightGrey;
            margin: 0 10px 10px;
        }

        .course-testimonial-slider-header {
            display: inline-block;
            text-transform: unset;
            font-style: italic;
            font-size: 17px;
            font-weight: 400;
            letter-spacing: 1.5px;
            color: $lightGrey;
            margin-bottom: 10px;
        }

        .star-rating {
            color: $goldFontColor;
            font-size: 16px;
            padding-bottom: 5px;
        }

        .testimonial {
            strong {
                font-weight: 500;
            }
        }
    }
}

.faq-section {
    color: $blue;
    border-bottom: 1px solid $orange;
    margin: 20px auto;

    h1 {
        color: $blue;
        font-size: 30px;
        font-weight: 400;
        text-transform: unset;
        letter-spacing: unset;
        margin-bottom: 20px;
    }

    h2 {
        color: $blue;
        font-size: 20px;
        font-weight: 400;
    }

    .faq {
        margin: 0 20px;
        transition: all 0.3s ease-out;
    }

    .faq:not(:last-child) {
        border-bottom: 1px solid $lightGrey;
    }

    .faq-answer-markdown {
        margin: 20px 0;
    }

    .faq-answer {
        overflow: hidden;
        height: 0;
    }

    .faq-question {
        font-size: 16px;
        font-weight: 700;
        margin: 20px 0;
        cursor: pointer;
        color: $orange;
        &:hover {
            color: $green;
        }

        i {
            float: right;
            padding-top: 4px;
        }
    }

    .faq-subsection {
        padding-top: 20px;
    }

    .faq-subsection:not(:first-child) {
        border-top: 1px solid $lightGrey;
    }
}

.featured-course {
    h2 {
        margin: 15px 0 0;
        padding: 4px 25px 10px 25px;
        background-color: rgba(0, 0, 0, 0.5);
        color: $white;
        letter-spacing: 0.1px;
        font-weight: 500;
    }

    .header-btn {
        color: $green;
        border-style: solid;
        border-color: transparent;
        border-radius: 50px;
        padding: 13px 50px;
        font-family: Roboto, sans-serif;
        font-weight: 400;
        font-size: 18px;
        margin: 15px auto 0;
        display: block;
        transition: all .3s;
        &:hover {
            color: $white;
            background-color: $green;
            border-color: $white;
        }
    }
}



.header-btn-container {
    background-color: $white;
    text-align: center;
    padding: 0 15px 15px;
    border-top: 7px solid;
    margin-top: 42px;
    &.blue {
        border-color: $blue;

        .header-btn {
            background-color: $blue;
            &:hover {
                color: $blue;
                background-color: $white;
                border-color: $blue;
            }
        }
    }
    &.green {
        border-color: $green;

        .header-btn {
            background-color: $green;
            &:hover {
                color: $green;
                background-color: $white;
                border-color: $green;
            }
        }
    }
    &.orange {
        border-color: $orange;

        .header-btn {
            background-color: $orange;
            &:hover {
                color: $orange;
                background-color: $white;
                border-color: $orange;
            }
        }
    }

    .header-btn {
        color: $white;
        border-style: solid;
        border-color: transparent;
        border-radius: 50px;
        padding: 13px 50px;
        font-family: $mainFont;
        font-weight: 400;
        font-size: 18px;
        margin: -31px auto 15px;
        display: block;
        transition: all .3s;
        &:hover {
            background-color: transparent;
        }
    }
}

.dark-font-button {
    fill: $white;
    color: $white;
    background-color: $blue;
    border-color: $blue;
    border-style: solid;
    border-radius: 50px;
    padding: 13px 50px;
    font-family: $mainFont;
    font-weight: 400;
    font-size: 18px;
    margin-top: 15px;
    transition: all .3s;
    &:hover {
        color: $blue;
        border-color: $blue;
        background-color: transparent;
    }
}

.header-details {
    position: relative;
    min-height: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
        color: $white;
        font-size: 61px;
    }

    .sub-header {
        font-weight: 500;
        font-size: 30px;
        color: $white;
        letter-spacing: 1.5px;
        line-height: 1em;
    }
}

.featured-course {
    justify-content: flex-end;
    padding: 30px 0;
}

.footer-container {
    position: relative;
    background-color: white;

    .bottom-footer-container {
        color: $lightGrey;
        font-size: 14px;
        font-weight: 400;
        margin: 40px auto;

        .bottom-footer {
            display: inline-block;
            text-align: right;

            .bottom-footer-section {
                display: inline-block;

                .fab {
                    color: $offBlack;
                    font-size: 36px;
                    &:hover {
                        color: $green;
                    }
                }
            }
        }

        .copyright-footer {
            display: inline-block;
            vertical-align: super;
        }
    }

    .middle-footer-container {
        background-color: $blue;
        color: $white;

        .middle-footer {
            padding: 25px 15px;
            display: inline-block;
            vertical-align: top;
            text-align: center;
            width: 33%;

            img {
                max-width: 300px;
                margin-bottom: 20px;
            }
        }
    }

    .top-footer-container {
        padding: 40px 0;

        h3 {
            color: $blue;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: unset;
            margin-left: -20px;
            &.green {
                color: $green;
            }
        }

        .top-footer {
            display: inline-block;
            vertical-align: top;
            margin-left: 40px;
            width: calc(20% - 40px);
            &:first-child h3 {
                margin-left: -40px;
            }
            &.form-submitted {
                font-size: 14px;
                color: $green;
                font-weight: 500;
                line-height: 29px;
            }
            &.submission-error {
                color: $orange;

                a {
                    color: $green;
                    &:hover, &:active, &:focus {
                        color: $orange;
                    }
                }
            }

            form {
                margin-left: -20px;

                input {
                    font-size: 14px;
                    color: $offBlack;
                    font-weight: 300;
                    border-radius: 0;
                    border-color: $green;
                    max-width: 172px;
                }

                .btn-footer-form {
                    color: $white;
                    background-color: $green;
                    border: 3px solid $green;
                    border-radius: 0;
                    padding: 5px 46px;
                    font-weight: 500;
                    font-size: 14px;
                    margin: 0 0 0 -5px;
                    text-transform: uppercase;
                    &:hover, &:active, &:focus {
                        color: $green !important;
                        background-color: transparent !important;
                        border-color: $green !important;
                    }
                }

                .form-group {
                    margin-bottom: 10px;
                }
            }

            .loader-container {
                width: 100%;
            }

            .top-footer-section {
                font-size: 14px;
                color: $offBlack;
                font-weight: 300;
                text-indent:-20px;
                &:nth-child(2) {
                    .fa {
                        margin: 10px 0 0;
                    }
                }
                &:not(:nth-child(2)) {
                    .fa {
                        margin: 20px 0 0;
                    }
                }

                a {
                    color: $offBlack;
                    &:hover {
                        color: $orange;
                    }
                }

                .fa {
                    font-size: 18px;
                    color: $green;
                    padding-right: 19px;
                }
            }

            .top-footer-link-section {
                margin: 10px 0;
            }
        }
    }
}

.form-submitted {
    color: $green;
}

.green-background-header {
    margin: 0 20px 0 0;
    padding: 4px;
    background-color: $green;
    width: 100%;
    color: $white;
    font-size: 25px;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
}

.green-banner-section {
    background-color: $green;
    color: $white;
    padding: 40px 0;
    text-align: center;
    position: relative;

    h1 {
        font-size: 25px;
        font-weight: bold;
        color: $white;
        letter-spacing: 2.5px;
    }
}

header {
    text-align: center;
    margin: 50px 0;
}

.highlight-container {
    margin-top: 20px;
}

.highlight-details-container {
    display: inline-block;
    width: calc(79% - 30px);
    text-align: left;
}

.highlight-details-detail {
    color: $offBlack;
    font-size: 18px;
    font-weight: 300;
}

.highlight-details-header {
    color: $blue;
    font-size: 21px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin-block-start: 1em;
    margin-block-end: 0.5em;
    line-height: 1em;
}

.highlight-details-icon {
    max-width: 111px;
}

.highlight-details-icon-container {
    width: 21%;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 20px;
}

.highlight-details-row {
    padding: 40px 0;
    color: $offBlack;
    font-size: 22px;
    font-weight: 300;
    text-align: center;
    line-height: 27px;

    h1 {
        color: $blue;
        font-size: 40px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 2.5px;
        text-align: center;
    }
}

.highlight-section-rows {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    &.resources {
        display: block;
        column-count: 2;

        .image-section {
            display: inline-block;
            width: 100%;
        }
    }

    .image-section {
        width: 33%;
        padding: 15px;
        text-align: center;

        div {
            text-align: left;
        }

        img {
            max-width: 125px;
            max-height: 125px;
            border-radius: 63px;
            margin-bottom: 20px;
        }

        .image-section-header {
            display: block;
            font-size: 20px;
            font-weight: 600;
            color: $blue;
            text-transform: uppercase;
            margin: 20px 0;
            letter-spacing: 1.3px;
        }

        .image-section-subheader {
            display: block;
            color: $darkGrey;
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 20px;

            div {
                text-align: center;
                text-transform: uppercase;
                line-height: 20px;
            }

            h6 {
                text-align: center;
            }
        }
    }

    .read-more-image-section {
        height: 485px;
        margin: 15px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
        border-radius: 3px;
        padding: 20px;

        .top-section {
            min-height: 390px;

            .header-img {
                text-align: center;
            }
        }
    }
}

.infinite-scroll-component {
    padding-top: 70px;
    column-gap: 15px;
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    align-items: flex-start;
}

.info-page {
    &.container {
        padding-bottom: 40px;
    }

    h2 {
        font-size: 20px;
        color: $offBlack;
    }

    .highlight-header {
        font-size: 20px;
        font-weight: 600;
        color: #0e3e67;
        text-transform: uppercase;
        margin: 20px 0;
        letter-spacing: 1.3px;
    }
}

.info-page-header {
    margin: 70px 0 -40px;
}

.learner-list-divider {
    width: 100%;
    margin-bottom: 30px;
    color: $lightGrey;
}

.loader-container {
    width: 1140px;
    margin: auto;
    text-align: center;
}

.main {
    padding-top: 76px;
}

.medium-show {
    display: none;
}

.mid-page-full-width {
    max-height: 400px;
    width: 100%;
    object-fit: cover;
    object-position: top center;
    margin-bottom: 30px;
}

.mid-section-banner {
    img {
        height: 300px;
        object-fit: cover;
        width: 100%;
    }
}

.mobile-menu {
    height: auto;
    position: absolute;
    background-color: $white;
    z-index: -1;
    top: 86px;
    padding: 3px 5px 1px;
    border: 1px solid $darkGrey;
    border-radius: 4px;

    .dropdown-menu {
        top: -5px !important;
        left: 155px;
    }

    .nav-link {
        font-size: 16px !important;
        height: 34px;
        overflow-y: hidden;
        padding: 5px 10px !important;
        border-bottom: 1px solid $lightGrey;
        &:hover {
            color: $white !important;
            background-color: $green;
        }
        &:last-child, &:nth-child(5) {
            &.d-sm-none {
                border-top: 1px solid $lightGrey;
            }
            border-bottom: 1px solid $white;
        }
    }

    .nav-item {

        a {
            &:not(.dropdown-item) {
                border-bottom: 1px solid $lightGrey !important;
            }
        }
    }
}

.tablet-show {
    display: none;
}

.modal-dialog {
    position: absolute;
    right: 71px;
    top: 38px;

    .modal-body {
        min-width: 500px;
    }

    .modal-footer {
        border-top: 1px solid $orange;
    }

    .modal-header {
        border-bottom: 1px solid $orange;
    }

    .modal-hr {
        width: 85%;
        margin-top: 25px;
        border-top: 1px solid $orange;
    }
}

.navbar-outer {
    position: fixed;
    z-index: 1;
    width: 100%;
    background-color: white;
    box-shadow: 0 1px 0 0 rgba(139, 141, 157, 0.05), 0 5px 10px 0 rgba(65, 71, 108, 0.15);

    .navbar-container {
        width: 95%;
        margin: auto;

        .navbar {
            background-color: $white;
            height: 76px;
            padding-left: 0;
            padding-right: 0;

            .dropdown-menu {
                border: 1px solid $darkGrey;
                border-radius: 4px;
                padding: 4px 5px 3px;
                top: 42px;

                a:last-child {
                    border-bottom: 1px solid $white;
                }

                .dropdown-item {
                    list-style: none;
                    height: 34px;
                    overflow-y: hidden;
                    padding: 5px 10px;
                    border-bottom: 1px solid $lightGrey;
                    color: $green;
                    &:hover {
                        color: $white !important;
                        background-color: $green;
                    }
                }
            }

            .header-icon {
                font-size: 21px;
                padding-top: 2px;
            }

            .navbar-brand {

                img {
                    max-height: 56px;
                }
            }

            .nav-item {
                margin: auto;
            }

            .nav-link {
                color: $green;
                font-size: 14px;
                padding: 0 16px;
                margin: auto;
                min-width: 80px;
                text-align: center;
                &:hover {
                    color: $orange;
                }
            }
        }
    }
}

.no-more-posts {
    color: $green;
    margin-top: 20px;
}

.no-validation {

    &:valid {
        border-color: #ced4da !important;
        background-image: none !important;
    }
}

.not-found {
    color: $blue;

    h1 {
        text-transform: unset;
        text-align: center;
        margin-top: 70px;
    }

    h2 {
        color: $blue;
        text-align: center;
        margin-bottom: 26px;
    }
}

.orange-background-header {
    margin: 0 20px 0 0;
    padding: 4px 4px 4px 25px;
    background-color: $orange;
    width: 100%;
}

.orange-banner-section {
    background-color: $orange;
    color: $white;
    padding: 40px 0;
    text-align: center;
    position: relative;

    h1 {
        font-size: 25px;
        font-weight: bold;
        color: $white;
        letter-spacing: 2.5px;
    }
}

.ordered-item-container {
    border: 1px solid $orange;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 20px;

    .fa-info-circle {
        color: $orange;
        cursor: pointer;
        margin-left: 1em;
    }
}

.organisations-hero-header {
    height: 400px;
}

.organisations-header-row {
    height: 400px;
    align-content: center;

    .organisations-header-left {
        background-color: $white;
        padding: 30px;
        height: 360px;
        line-height: 27px;

        .header-details {
            min-height: 360px;
        }

        h1 {
            font-size: 36px;
            font-weight: 700;
            color: $green;
            text-transform: unset;
            letter-spacing: 1.2px;
            margin-bottom: 15px;
        }

        .text-block-container {
            margin-top: 30px;

            .text-block {
                font-size: 20px;
            }
        }
    }

    .organisations-header-right {
        height: 360px;

        .organisations-image {
            max-width: 540px;
            height: 360px;
            object-fit: cover;
        }
    }
}

.organisations-packages-container {
    background-color: $offWhite;
    position: relative;
    padding: 40px 0;

    .organisations-packages-details {
        color: $offBlack;
        text-align: center;
        font-size: 20px;
    }

    .package-box {
        background-color: transparent;
        padding: 0;
        border-radius: 0;
        height: auto;

        .header-btn {
            padding: 13px 40px;
        }
    }
}

.organisations-partner-carousel {
    background-color: $beige;
    padding: 40px 70px 30px;
    font-family: $mainFont;

    img {
        max-width: 150px;
    }

    .header-container {
        margin-bottom: 25px;
        padding: 0;
    }

    .slide {
        background-color: transparent;
        min-height: unset;
        height: 150px;
        padding: 0 20px;
    }

    .rec-dot:not(.rec-dot_active) {
        background-color: $lightGrey;
    }

    .rec-pagination {
        margin-top: 0;
    }

    .sub-header {
        font-size: 40px;
        text-transform: uppercase;
        color: $blue;
        font-weight: 500;
    }

    .partner {
        background-color: $blue;
        color: $white;
        padding: 25px 55px;
        font-size: 18px;
        font-weight: 300;
        min-height: 215px;
        &:after {
            content: "";
            display: block;
            width: 14px;
            height: 14px;
            background-color: $blue;
            position: absolute;
            top: 228px;
            -webkit-transform: scaleX(.75) rotate(
                            45deg
            );
            -ms-transform: scaleX(.75) rotate(45deg);
            transform: scaleX(.75) rotate(
                            45deg
            );
            border-width: 0;
            -webkit-box-sizing: content-box;
            box-sizing: content-box;
        }
    }

    .partners-header {
        color: $blue;
        font-size: 40px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
}

.organisations-testimonial-carousel {
    background-color: $mintCream;
    padding: 40px 10px;
    font-family: $mainFont;

    .caption {
        font-size: 20px !important;
        font-weight: 600 !important;
        color: $orange !important;
    }

    .header-container {
        margin-bottom: 10px;
    }

    .header-info {
        margin-top: 20px;
        width: 75%;
        margin-right: auto;
        text-align: left;
        margin-left: auto;
        height: unset !important;
    }

    .rec-dot:not(.rec-dot_active) {
        background-color: $lightGrey;
    }

    .rec-carousel-wrapper {
        height: 600px;
    }

    .slide {
        min-height: unset;

        .info-container {
            min-height: 150px;
        }
    }

    .slider-header {
        font-size: 18px;
        font-weight: 300;
    }

    .sub-header {
        font-size: 40px;
        text-transform: uppercase;
        color: $blue;
        font-weight: 500;
    }

    .testimonial {
        background-color: $blue;
        color: $white;
        padding: 25px 55px;
        font-size: 18px;
        font-weight: 300;
        min-height: 375px;
        text-align: left;
        &:after {
            content: "";
            display: block;
            width: 14px;
            height: 14px;
            background-color: $blue;
            position: absolute;
            top: 388px;
            -webkit-transform: scaleX(.75) rotate(
                         45deg
            );
            -ms-transform: scaleX(.75) rotate(45deg);
            transform: scaleX(.75) rotate(
                         45deg
            );
            border-width: 0;
            -webkit-box-sizing: content-box;
            box-sizing: content-box;
        }
    }

    .testimonials-header {
        color: $blue;
        font-size: 40px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 2.5px;
    }
}

.outcomes-subheader {
    font-size: 18px;
    font-weight: 500;
    color: $offBlack;
    margin: 20px 0 25px;
}

.package-box {
    width: calc(33% - 30px);
    padding: 15px;
    margin: 20px 15px;
    background-color: $white;
    display: inline-block;
    border-radius: 4px;
    height: 240px;

    .box-header {
        font-size: 20px;
        font-weight: 500;
        color: $blue;
        text-align: center;
        margin-bottom: 5px;
    }

    .btn {
        width: calc(100% - 30px);
        margin: 20px 15px 10px;
        text-align: center;
    }

    .package-button {
        position: absolute;
        bottom: 70px;
        width: 305px;
    }
}

.partner-carousel {
    background-color: $beige;
    padding: 40px 70px 30px;
    font-family: $mainFont;

    img {
        max-width: 150px;
    }

    .header-container {
        margin-bottom: 25px;
    }

    .slide {
        background-color: transparent;
        min-height: unset;
        height: 150px;
        padding: 0 20px;
    }

    .rec-dot:not(.rec-dot_active) {
        background-color: $lightGrey;
    }

    .rec-pagination {
        margin-top: 0;
    }

    .sub-header {
        font-size: 40px;
        text-transform: uppercase;
        color: $blue;
        font-weight: 500;
        line-height: 40px;
    }

    .partner {
        background-color: $blue;
        color: $white;
        padding: 25px 55px;
        font-size: 18px;
        font-weight: 300;
        min-height: 215px;
        &:after {
            content: "";
            display: block;
            width: 14px;
            height: 14px;
            background-color: $blue;
            position: absolute;
            top: 228px;
            -webkit-transform: scaleX(.75) rotate(
                            45deg
            );
            -ms-transform: scaleX(.75) rotate(45deg);
            transform: scaleX(.75) rotate(
                            45deg
            );
            border-width: 0;
            -webkit-box-sizing: content-box;
            box-sizing: content-box;
        }
    }

    .partners-header {
        font-size: 20px;
        font-weight: 300;
        color: $lightGrey;
        margin-bottom: 40px;
    }
}

.payment-success.container {
    margin-top: 50px;
    text-align: center;

    form {
        text-align: left;
    }

    h1 {
        font-size: 24px;
        letter-spacing: unset;
        text-transform: none;
    }

    h2 {
        font-size: 20px;
        color: $blue;
    }

    .btn-primary {
        margin-left: 20px;
    }

    .ordered-items {
        text-align: left;
        margin: 30px 0;

        img {
            height: 100%;
            max-width: 245px;
            object-fit: cover;
            float: left;
            margin-right: 10px;
            max-height: 140px;
        }

        .learner-list {

            .rubric {
                margin-bottom: 15px;
            }
        }

        .order-discount {
            color: $green;
            font-weight: 500;
        }

        .ordered-item {
            height: 190px;
            margin: 20px 0;

            .ordered-item-bottom {
                width: calc(100% - 255px);
                display: inline-block;
                vertical-align: top;
            }

            .ordered-item-top {
                width: 255px;
                height: 140px;
                display: inline-block;
            }
        }

        .ordered-item-details {
            text-align: right;

            .detail {
                font-weight: 500;
                color: $blue;

                .quantity-update {
                    color: $white;
                    background-color: $orange;
                    border: 2px solid $orange;
                    border-radius: 5px;
                    padding: 0 5px;
                    font-family: Roboto, sans-serif;
                    font-weight: 500;
                    font-size: 18px;
                    transition: all .3s;
                    line-height: 16px;
                    &.quantity-add {
                        margin-left: 5px;
                        padding: 0 3px;
                    }
                    &.quantity-remove {
                        margin-right: 5px;
                        padding: 0 5px;
                    }
                    &:hover {
                        color: $orange;
                        background-color: $white;
                        border: 2px solid $orange;
                        transition: all .3s;
                    }
                }
            }

            .start-date select {
                display: inline-block;
                width: auto;
                margin: 0 0 5px 5px;
                max-width: calc(100% - 5px);
            }
        }

        .ordered-item-name {
            font-weight: 500;
            width: 100%;
            float: right;
            margin: 0 0 20px;
            color: $blue;
            text-align: center;
        }

        .ordered-item-total {
            text-align: right;
            width: calc(100% - 165px);
            margin-left: 145px;
            padding-top: 30px;

            .detail {
                font-weight: 500;
                color: $blue;
            }
        }
    }
}

.pricing-section {
    background-color: $offWhite;
    padding: 50px 0;
    text-align: center;
    font-weight: 400;

    ul {
        padding-inline-start: 17px;
    }

    .image-section-container {
        justify-content: space-evenly;
    }

    .pricing-bubble {
        color: $green;
        margin: -10px auto 20px;
        font-size: 25px;
        font-weight: 700;
        text-align: center;
    }

    .pricing-button {
        text-align: center;

        .btn-primary {
            &:hover, &:active, &:focus {
                background-color: $white !important;
            }
        }

        .btn-primary-inverse {
            border: 3px solid $orange;
            padding: 10px 50px;
            &:hover, &:active, &:focus {
                color: $white !important;
                background-color: $orange !important;
                border-color: $orange !important;
            }
        }
    }

    .pricing-div-standard {
        background-color: $lightBlue;
        border: 8px solid $white;
        margin: -8px 0;
    }

    .pricing-header {
        text-align: center;
        font-size: 30px;
        font-weight: 700;
        color: $blue;
        margin-top: 25px;
    }

    .pricing-text-section {
        height: 325px;

        .fa-info-circle {
            color: $orange;
            cursor: pointer;
        }
    }

    .recommended {
        color: $white;
        background-color: $blue;
        text-align: center;
        width: 130px;
        margin-left: calc(50% - 65px);
        margin-top: -28px;
        text-transform: uppercase;
        font-size: 14px;
        padding: 3px 0;
     }
}

.pricing-table-container {
    padding: 40px;
    position: relative;
    background-color: $white;

    h1 {
        text-align: center;
    }

    .pricing-table {
        margin: 30px auto 40px;

        .pricing-col {
            padding: 20px;
            text-align: center;
            min-width: 145px;

            &:not(:last-child) {
                border-right: 1px solid $lightGrey;
            }
        }

        .pricing-col-1 {
            background-color: $offWhite;
            font-weight: 700;
            text-align: left;
        }

        .pricing-col-2 {
            background-color: $lightGreen;
        }

        .pricing-col-3 {
            background-color: $orange;
            color: $white;
        }

        .pricing-col-4 {
            background-color: $blue;
            color: $white;
        }

        .pricing-col-5 {
            background-color: $green;
            color: $white;
        }

        .row {
            justify-content: center;
            &:not(:last-child) .pricing-col {
                border-bottom: 1px solid $lightGrey;
            }
        }
    }
}

.react-confirm-alert-overlay {
    background: rgba(255, 255, 255, 0.6);

    .confirm-invoice-alert {
        &.alert-popup {
            display: block;
        }

        h1 {
            width: 240px;
            display: inline-block;
            line-height: 1.2em;
        }

        .confirm-invoice-alert-header {
            width: 280px;
        }

        .fa-exclamation-circle {
            margin-right: 0;
            margin-left: 10px;
            vertical-align: super;
        }
    }
}

.read-more-link {
    cursor: pointer;
    color: $green;
    &:hover {
        color: $orange;
    }
}

.read-more-modal {

    .modal-dialog {
        width: 900px;
        max-width: 900px;

        .modal-content {
            border-left: 10px solid $green;

            .modal-header {
                padding: 16px 16px 0;
                border-bottom: 0;
            }

            .modal-body {
                padding-top: 0;

                .left-column {
                    width: 33%;
                    float: left;
                    padding: 20px;
                    text-align: center;

                    img {
                        max-width: 100%;
                        margin-bottom: 20px;
                    }

                    .image-section-header {
                        font-size: 30px;
                        font-weight: 600;
                        color: $green;
                    }

                    .image-section-subheader {
                        color: $darkGrey;
                        font-size: 20px;
                        font-weight: 500;
                        text-transform: uppercase;
                        line-height: 24px;
                        margin-top: 15px;
                    }
                }

                .right-column {
                    width: 66%;
                    float: right;
                    padding: 20px;
                    border-left: 10px solid $blue;
                }
            }
        }
    }
}

.remove-coaching-button {
    margin-top: 1em;

    button {
        border: none;
        color: $orange !important;
        cursor: pointer;

        &:hover {
            color: $green !important;
        }
    }
}

.required-field {
    color: $orange;
    margin-left: 5px;
}

.search-form {

    .search-container {
        overflow: hidden;
        width: 115px;

        .search-input {
            width: 100%;
            border-color: $darkGrey;
            color: $darkGrey;
        }
    }

    .site-search {
        color: $green;
        font-size: 20px;
        cursor: pointer;
        padding-left: 10px;
        &:hover {
            color: $orange;
        }
    }
}

.search-suggestions {
    position: absolute;
    left: 510px;
    top: 70px;
    width: 400px;
    padding: 4px 5px 3px;
    background-color: $white;
    border: 1px solid $darkGrey;
    border-radius: 4px;

    a {
        &:last-child {
            li {
                border-bottom: 1px solid $white;
            }
        }
    }

    li {
        list-style: none;
        height: 34px;
        overflow-y: hidden;
        padding: 5px 10px;
        border-bottom: 1px solid $lightGrey;
        &:hover {
            color: $white !important;
        }
        &.course-suggestion {
            color: $green;
            &:hover {
                background-color: $green;
            }
        }
        &.faq-suggestion {
            color: $orange;
            &:hover {
                background-color: $orange;
            }
        }
        &.info-suggestion {
            color: $blue;
            &:hover {
                background-color: $blue;
            }
        }
        &.blog-suggestion {
            color: $offBlack;
            &:hover {
                background-color: $offBlack;
            }
        }
        &.no-search-results {
            border-bottom: none;
            color: $offBlack;
            &:hover {
                color: $offBlack !important;
            }
        }
    }
}

.sitemap-links {
    margin-top: 20px;

    .sitemap-link {
        color: $blue;
    }
}

.small-grey {
    font-size: 0.8em;
    color: $darkGrey;
}

.tAndCs {
    margin-top: 25px;
}

.testimonial-carousel {
    background-color: $mintCream;
    padding: 70px 10px;
    font-family: $mainFont;

    .caption {
        font-size: 20px !important;
        font-weight: 600 !important;
        color: $orange !important;
    }

    .header-info {
        margin-top: 40px;
        width: 75%;
        margin-right: auto;
        text-align: left;
        margin-left: auto;
        height: unset !important;
    }

    .info-container {
        min-height: 215px;
    }

    .rec-dot:not(.rec-dot_active) {
        background-color: $lightGrey;
    }

    .slide {
        min-height: unset;
    }

    .slider-header {
        font-size: 18px;
        font-weight: 300;
    }

    .sub-header {
        font-size: 40px;
        text-transform: uppercase;
        color: $blue;
        font-weight: 500;
        line-height: 40px;
    }

    .testimonial {
        background-color: $blue;
        color: $white;
        padding: 25px 55px;
        font-size: 18px;
        font-weight: 300;
        min-height: 375px;
        text-align: left;
        &:after {
            content: "";
            display: block;
            width: 14px;
            height: 14px;
            background-color: $blue;
            position: absolute;
            top: 388px;
            -webkit-transform: scaleX(.75) rotate(
                            45deg
            );
            -ms-transform: scaleX(.75) rotate(45deg);
            transform: scaleX(.75) rotate(
                            45deg
            );
            border-width: 0;
            -webkit-box-sizing: content-box;
            box-sizing: content-box;
        }
    }

    .testimonials-header {
        font-size: 20px;
        font-weight: 300;
        color: $lightGrey;
        margin-bottom: 40px;
    }
}

.text-block-container {
    margin: 70px 0 30px;

    .page-sub-header {
        font-size: 30px;
        font-weight: 500;
        color: $blue;
    }
}

.thank-you {
    padding-top: 146px;
}

.video-player {
    width: 1110px;
    height: 624px;
    margin: 0 auto;
}

.white-btn {
    fill: $white;
    color: $orange;
    background-color: $white;
    border: 3px solid $white;
    border-radius: 50px;
    padding: 10px 50px;
    font-family: $mainFont;
    font-weight: 400;
    font-size: 16px;
    margin: 15px 30px 0;
    transition: all .3s;
    &:hover, &:active, &:focus {
        color: $white !important;
        background-color: transparent !important;
        border-color: $white !important;
    }
}

.who-this-course-is-for {
    padding: 50px 0 0;
    text-align: center;
    &.our-expertise {
        padding: 70px 0;
        background-color: $white;
        position: relative;

        h1 {
            text-align: left;
        }

        .image-section {

            div {
                &:last-child {
                    text-align: left;
                }
            }
        }

        .image-section-container {

            .image-section-header {
                min-height: 90px;
            }
        }
    }
    &:not(.our-expertise) {

        .container {

            h1, > div:first-of-type {
                text-align: left;
            }

            .image-section {

                .image-section-header, div {
                    text-align: left;
                }
            }
        }
    }

    .image-section-container {
        margin-top: 30px;
        font-size: 16px;
        font-weight: 300;

        img {
            width: 125px;
            margin-bottom: 30px;
        }

        .image-section-header {
            font-size: 20px;
            font-weight: 600;
            color: $blue;
            text-transform: uppercase;
            display: block;
            margin-bottom: 20px;
            letter-spacing: 1.3px;
        }
    }
}

.why-us {
    background-color: $offWhite;
    position: relative;
    padding: 40px 0;

    .organisations-mentor-carousel {
        background-color: transparent;
        padding: 0;
        width: 33%;
        display: inline-block;
        border-radius: 4px;
        height: 420px;
        vertical-align: middle;

        .container {
            padding: 0;
        }

        .header-btn-container {
            width: calc(100% - 64px);
            margin: 42px auto 0;
            padding: 5px 15px;
        }

        .header-container {
            margin-bottom: 0;

            .organisations-mentor-header {
                color: $blue;
                font-size: 20px;
                font-weight: 500;
                text-align: center;
                margin-bottom: 5px;
            }
        }

        .package-box {
            width: 100%;
            margin: 0;
            background-color: $white;
            padding: 15px;

            .package-button {
                width: 245px;
            }
        }

        .rec-arrow {
            color: $lightGrey;
        }

        .rec-carousel-item {
            height: 355px;
        }

        .rec-carousel-wrapper {
            height: 355px;
        }

        .rec-dot {
            background-color: $lightGrey;
        }

        .rec-dot_active {
            background-color: $blue;
        }

        .slide {
            height: 340px;
            min-height: 340px;
            width: 100%;
            padding: 0 15px 15px;
            margin: 0;

            img {
                width: 100px;
                height: auto;
                border-radius: 50px;
            }

            .info-container {
                hr {
                    border-top: 2px solid $blue;
                    width: 33%;
                    margin-top: 0;
                }

                .caption {
                    font-size: 20px;
                }

                .header-info {
                    height: 70px;
                }

                .slider-body {
                    font-weight: 300;
                }

                .slider-header {
                    letter-spacing: 0.5px;
                }
            }
        }
    }

    .organisations-packages-details {
        color: $white;
        text-align: center;
    }

    .package-box {
        height: 420px;
        vertical-align: middle;
        background-color: transparent;
        padding: 0;
        border-radius: 0;

        .header-btn-container {
            height: 378px;

            .header-btn {
                padding: 13px 40px;
            }

            .header-btn-blurb {
                text-align: left;
            }
        }
    }
}

.ytp-pause-overlay {
    display: none;
}

.zero-height {
    height: 0 !important;
    overflow: hidden;
    padding: 0;
    border: none;
}

.zero-width {
    width: 0 !important;
}
// 1199px
@media (max-width: 74.9375rem) {

    .approach-experience {
        padding: 40px 10px;

        .course-experience-testimonial {
            padding: 25px;
        }

        .testimonial-carousel {

            .container {
                padding: 0;

                .rec-slider-container {
                    margin: 0;

                    .slide {
                        margin: 0;
                        padding: 10px;
                    }
                }
            }
        }
    }

    .carousel {
        padding: 40px 10px;
    }

    .coaching-package-container {

        .pricing-text-section {
            height: 470px;
        }
    }

    .contact-details-container {
        padding: 15px;
    }

    .course-hero-header {

        .background-overlay {
            height: 656px;
        }

        .course-header-row {
            max-width: 960px;

            .course-header-left-container {
                padding: 15px 0;

                .course-header-left {
                    height: 690px;
                }
            }

            .course-header-right {

                .course-top-right-image-container {
                    max-width: 960px;
                    margin: auto;
                }

                .course-top-right-info-container {
                    height: 366px;
                }
            }
        }
    }

    .course-mentor-carousel {

        .slide {
            height: 510px;
        }
    }

    .header-background-overlay {
        flex: 0 0 calc(33% - 30px);
    }

    .header-btn-container {
        min-height: 114px;

        .header-btn {
            padding: 13px 35px;
        }

        .header-btn-blurb {
            font-size: 15px;
        }
    }

    .highlight-section-rows {

        .read-more-image-section {
            height: 535px;

            .top-section {
                min-height: 440px;
            }
        }
    }

    .loader-container {
        width: 100%;
    }

    .navbar {

        .dropdown {
            margin: auto;

            .dropdown-toggle {
                &:after {
                    display: none;
                }
            }
        }

        .nav-link {
            white-space: break-spaces;
            margin: auto;
        }
    }

    .orange-banner-section, .green-banner-section {

        h1 {
            letter-spacing: 1px;
        }
    }

    .organisations-header-row {

        .organisations-header-left {
            margin-left: 15px;
            max-width: calc(50% - 15px);
        }

        .organisations-header-right {

            .organisations-image {
                max-width: 100%;
            }
        }
    }

    .organisations-packages-container {

        .package-box {
            vertical-align: middle;

            .header-btn {
                padding: 13px 25px;
            }

            .header-btn-container {
                min-height: 175px;
            }
        }
    }

    .organisations-testimonial-carousel {

        .header-info {
            min-height: 175px;
        }

        .info-container {
            min-height: 250px;
        }

        .rec-carousel-wrapper {
            height: 645px;

            .rec-carousel {
                min-height: 625px;
            }
        }

        .testimonial {
            padding: 25px;
            min-height: 405px;
            &:after {
                top: 418px;
            }
        }
    }

    .testimonial-carousel {

        .testimonial {
            padding: 25px;
            min-height: 325px;
            &:after {
                top: 58%;
            }
        }
    }

    .video-player {
        width: 930px;
        height: 523px;
    }

    .who-this-course-is-for {
        &.our-expertise {
            padding: 40px 10px;
        }

        .image-section-container {
            margin-top: 40px;

            .image-section-header {
                min-height: 60px;
            }
        }
    }

    .why-us {

        .organisations-mentor-carousel {

            .header-btn-container {
                min-height: unset;
                padding: 1px 15px;
                width: calc(100% - 44px);
            }

            .rec-slider-container {
                margin: 0;
                min-height: 330px;
            }

            .slide {

                .info-container {

                    .caption {
                        margin-bottom: 5px;
                    }

                    .slider-header {
                        line-height: 1em;
                    }
                }
            }
        }

        .package-box {

            .header-btn-container {
                height: 380px;
            }
        }
    }
}
// 991px
@media (max-width: 61.9375rem) {

    .approach-experience {

        .testimonial-carousel {

            .testimonial {
                min-height: 215px;
                &:after {
                    top: 218px;
                }
            }
        }
    }

    .blog-list-post {
        max-width: calc(50% - 20px);
    }

    .btn-primary {
        padding: 10px 45px;
    }

    .carousel {
        &.our-approach-carousel, &.coaching-benefits-carousel {

            .slide {

                .highlight-details-container {

                    .highlight-details-header {
                        min-height: 85px;
                    }
                }
            }
        }

        .header-container {
            padding: 0;
        }

        .rec-slider-container {
            margin: 0;
        }

        .slide {
            min-height: 415px;
        }
    }

    .coaching-package-container {
        max-width: 100%;
        margin-bottom: 40px;

        .pricing-text-section {
            height: auto;
        }
    }

    .contact-details-container {
        font-size: 14px;

        .contact-icon {

            i {
                font-size: 26px;
                width: 35px;
            }
        }
    }

    .course-header-left-container {
        max-width: unset;
    }

    .course-hero-header {
        height: auto;
        background-image: none !important;
        background-color: $blue;
        max-width: unset;

        .background-overlay {
            display: none;
        }

        .course-header-row {
            height: unset;

            .course-header-left-container {
                margin: 15px auto;
                max-width: unset;

                .course-header-left {
                    height: unset;
                }
            }

            .course-header-right {

                .course-top-right-info-container {
                    height: 486px;

                    .button-container {

                        .btn-primary {
                            margin: 10px auto 0;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .course-information-section {

        .course-header-row {

            .course-top-right-info-container {

                .course-information {
                    -webkit-column-width: 12em;
                    column-width: 12em;
                }
            }
        }
    }

    .course-main-section {
        max-width: unset;
    }

    .course-mentor-carousel {

        .slide {
            height: 630px;
        }
    }

    .course-module-carousel {

        .slide {

            img {
                max-width: 100%;
            }

            .info-container {

                .header-info {
                    height: 185px;
                }
            }
        }
    }

    .course-testimonial-carousel {

        .slide {
            min-height: unset;
        }
    }

    .footer-container {

        .top-footer-container {

            .top-footer {
                width: calc(50% - 40px);
                text-align: center;
                margin-bottom: 30px;
                &:last-child {
                    width: calc(100% - 80px);

                    form {
                        margin-left: auto;

                        .form-group {
                            margin: 0 auto 10px;
                        }
                    }

                    h3 {
                        margin-left: 0;
                    }
                }
            }
        }

        .middle-footer-container {

            .middle-footer {
                width: 100%;
            }
        }
    }

    .header-background-overlay {
        flex: 0 0 100%;
        background-repeat: no-repeat;
        background-size: cover;

        .featured-course {
            justify-content: center;
        }
    }

    .header-row {
        height: auto;
    }

    .highlight-section-rows {

        .image-section {

            img {
                max-width: 125px;
                border-radius: 63px;
            }
        }

        .read-more-image-section {
            height: 655px;

            .top-section {
                min-height: 560px;
            }
        }
    }

    .medium-hide {
        display: none;
    }

    .medium-show {
        display: flex;
    }

    .navbar-outer {

        .navbar-container {

            .navbar {

                .navbar-brand {
                    margin-left: 15px;
                }
        
                .mobile-menu {
        
                    .nav-link {
                        max-width: 150px;
                        text-align: left;
                    }
                }
        
                .navbar-toggler {
                    border: 0;
                    color: $lightGrey;
                    padding: 5px 1px 2px 10px;
                    font-size: 36px;
                    cursor: pointer;
                }
        
                .cart {
                    margin: unset;
                }
        
                .search-form {
                    width: 20%;
                    justify-content: flex-end;
        
                    .search-container {
                        width: 90%;
                    }
                }
        
                .search-suggestions {
                    left: 21%;
                }
            }
        }
    }

    .orange-banner-section, .green-banner-section {
        .white-btn {
            padding: 10px 20px;
            margin: 15px 0 0;
        }
    }

    .course-orange-banner-section, .course-green-banner-section {

        .white-btn {
            margin: 15px 15px 0;
        }
    }

    .organisations-header-row {
        height: 450px;

        .organisations-header-left {
            height: 415px;
        }

        .organisations-header-right {
            height: 415px;

            .organisations-image {
                height: 415px;
            }
        }
    }

    .organisations-hero-header {
        height: 450px;
    }

    .organisations-packages-container {

        .package-box {

            .header-btn-container {
                min-height: 240px;

                .header-btn {
                    padding: 13px 15px;
                    font-size: 16px;
                }
            }
        }
    }

    .organisations-partner-carousel {

        .header-container {
            padding: 0;

            .partners-header {
                font-size: 38px;
            }
        }

        .slide {
            min-height: 150px;
        }
    }

    .organisations-testimonial-carousel {

        .info-container {
            min-height: unset;
        }

        .rec-carousel-wrapper {
            height: 470px;

            .rec-carousel {
                min-height: unset;
            }
        }

        .testimonial {
            min-height: 270px;
            &:after {
                top: 283px;
            }
        }
    }

    .partner-carousel {

        .slide {
            min-height: unset;
        }
    }

    .pricing-section {

        .image-section-container {
            flex-direction: column-reverse;
            margin-right: -10px;
            margin-left: -20px;
        }

        .pricing-div-standard {
            margin: 0 5px 40px;
        }
    }

    .pricing-table-container {

        .pricing-table {
            overflow-x: scroll;

            .row {
                min-width: 870px;
            }
        }
    }

    .read-more-modal .modal-dialog {
        width: 708px;
        right: 30px;
    }

    .testimonial-carousel {

        .info-container {
            min-height: 170px;
        }

        .testimonial {
            min-height: 215px;
            &:after {
                top: 228px;
            }
        }
    }

    .video-player {
        width: 690px;
        height: 388px;
    }

    .who-this-course-is-for {
        &.our-expertise {

            .image-section-container {

                .image-section-header {
                    min-height: 150px;
                }
            }
        }

        .container {
            max-width: unset;
        }

        .image-section-container {

            .image-section-header {
                min-height: 90px;
            }
        }
    }

    .why-us {

        .container {
            padding: 0;
        }

        .organisations-mentor-carousel {
            margin-left: -15px;
            margin-right: -15px;
            height: 500px;

            .rec-slider-container {
                height: 400px;

                .rec-carousel-item {
                    height: 400px;

                    .slide {
                        height: 400px;
                    }
                }
            }
        }

        .package-box {
            height: 500px;

            .header-btn-container {
                height: 450px;

                .header-btn {
                    padding: 13px 30px;
                }

                .header-btn-blurb {

                    ul {
                        padding-inline-start: 15px;
                    }
                }
            }
        }
    }
}
// 767px
@media (max-width: 47.9375rem) {

    .approach-experience {
        padding: 40px 10px;

        h1 {
            font-size: 34px;
            line-height: 34px;
        }

        .testimonial-carousel {

            .testimonial {
                min-height: 320px;
                &:after {
                    top: 323px;
                }
            }
        }
    }

    .blog-list-post {
        max-width: calc(100% - 20px);
    }

    .btn-primary {
        padding: 10px 20px;
    }

    .carousel {
        padding: 40px 10px;
        &.our-approach-carousel, &.coaching-benefits-carousel {

            .our-approach-subheader {
                margin-top: 0;
            }
        }

        h1 {
            font-size: 34px;
            line-height: 34px;
        }
    }

    .coaching-options-container {

        .coaching-option {

            .coaching-option-text {
                margin-bottom: 40px;
            }
        }
    }

    .coaching-testimonial-image-container {
        text-align: center;
        margin-top: 30px;
    }

    .contact-details-container {
        font-size: 16px;

        .contact-icon {

            i {
                font-size: 29px;
                width: 40px;
            }
        }
    }
    .course-hero-header {

        .course-header-row {
            flex-direction: unset;

            .course-header-right {
                text-align: center;
                margin-top: 5px;

                h1 {
                    font-size: 30px;
                }

                h2 {
                    font-size: 20px;
                }

                .btn-primary {
                    padding: 5px 20px;
                }
            }

            .course-top-right-image-container {
                text-align: center;

                .course-image {
                    max-width: 250px;
                }
            }
        }
    }

    .course-mentor-carousel {

        .slide {
            height: 395px;
            min-height: unset;
        }
    }

    .course-module-carousel {

        .slide {
            height: 430px;
            min-height: unset;

            .info-container {

                .header-info {
                    height: 110px;
                }
            }
        }
    }

    .header-btn-container {

        .header-btn {
            padding: 13px 15px;
        }
    }

    .highlight-section-rows {

        .read-more-image-section {
            height: unset;

            .top-section {
                min-height: unset;
            }
        }
    }

    .info-page {

        .highlight-header {
            text-align: center;
            color: $green;
        }

        .highlight-section-rows {

            .image-section {
                width: 100% !important;

                img {
                    max-width: 250px;
                    border-radius: 125px;
                }
            }
        }
    }

    .mobile-menu {

        .dropdown-menu {
            max-width: 388px;
        }
        
        .nav-link {
            &:nth-child(5) {
                border-bottom: 1px solid $lightGrey;
            }
        }
    }

    .modal-dialog {
        right: 10px;
    }

    .navbar {

        &>.nav-link {
            margin: 0;
            padding: 0 !important;
        }

        .nav-link {
            min-width: unset !important;
            max-width: unset;
        }

        .search-form {
            width: 50%;
        }

        .search-suggestions {
            left: 15%;
        }
    }

    .organisations-header-row {
        height: 795px;

        .organisations-header-left {
            max-width: calc(100% - 30px);
            margin-bottom: 15px;
            height: 330px;
        }
    }

    .organisations-hero-header {
        height: 795px;
    }

    .organisations-packages-container {

        h1 {
            font-size: 34px;
            line-height: 34px;
        }

        .package-box {
            width: 100%;
            margin: auto;

            .header-btn-container {
                min-height: 135px;

                .header-btn {
                    padding: 13px 40px;
                    font-size: 18px;
                }
            }
        }
    }

    .organisations-partner-carousel {

        .header-container {

            .partners-header {
                font-size: 26px;
            }
        }
     }

    .organisations-testimonial-carousel {

        .header-info {
            min-height: 200px;
        }

        .rec-carousel-wrapper {
            height: 625px;
        }

        .testimonial {
            min-height: 375px;
            &:after {
                top: 388px;
            }
        }

        .testimonials-header {
            font-size: 34px;
            line-height: 34px;
        }
    }

    .partner-carousel {
        padding: 40px 10px 30px;

        .sub-header {
            font-size: 34px;
            line-height: 34px;
        }
    }

    .payment-success {
        &.container {

            .btn-primary {
                margin-left: 0;
            }

            .ordered-items {

                .ordered-item {
                    height: auto;

                    img {
                        width: 100%;
                        max-width: unset;
                        height: auto;
                        float: unset;
                        max-height: unset;
                    }

                    .ordered-item-details {
                        width: 100%;
                        margin: 15px 0;
                    }

                    .ordered-item-name {
                        width: 100%;
                        margin: 15px 0;
                        float: unset;
                    }

                    .ordered-item-bottom {
                        width: unset;
                        height: 100%;
                        display: block;
                    }

                    .ordered-item-top {
                        width: unset;
                        height: 100%;
                        display: block;
                    }
                }

                .ordered-item-total {
                    margin-left: 165px;
                }
            }
        }
    }

    .read-more-modal {

        .modal-dialog {
            width: 516px;

            .modal-content {

                .modal-body {

                    .left-column {
                        width: 100%;
                        float: unset;

                        img {
                            max-width: 50%;
                            margin: 0 auto 20px;
                            display: block;
                        }
                    }

                    .right-column {
                        width: 100%;
                        float: unset;
                        border-left: 0;
                        border-top: 10px solid #0e3e67;
                    }
                }
            }
        }
    }

    .search-hide {
        display: none;
    }

    .tablet-hide {
        display: none;
    }

    .tablet-show {
        display: flex;
    }

    .tAndCs {
        margin-top: 0;
    }

    .testimonial-carousel {

        .sub-header {
            font-size: 34px;
            line-height: 34px;
        }

        .testimonial {
            min-height: 265px;
            &:after {
                top: 278px;
            }
        }
    }

    .video-player {
        width: 510px;
        height: 287px;
    }

    .who-this-course-is-for {
        &.our-expertise {
            padding: 40px 0;

            .image-section-container {
                margin-top: 30px;

                .image-section {
                    margin-bottom: 30px;
                }

                .image-section-header {
                    min-height: unset;
                }
            }
        }
        &:not(.our-expertise) {

            .image-section-container {

                .image-section {
                    margin-bottom: 40px;

                    img {
                        margin-bottom: 10px;
                    }

                    .image-section-header {
                        min-height: unset;
                    }
                }
            }
        }

        h1 {
            font-size: 34px;
            line-height: 34px;
        }
    }

    .why-us {

        h1 {
            font-size: 34px;
            line-height: 34px;
        }

        .organisations-mentor-carousel {
            width: 100%;
            margin: auto;
            height: 425px;

            .header-btn-container {

                .header-btn {
                    padding: 13px 40px;
                }
            }

            .package-box {
                height: 290px;
            }

            .rec-carousel-wrapper {
                height: 330px;

                .rec-slider-container {
                    height: 290px;
                    min-height: unset;

                    .rec-carousel-item {

                        .slide {
                            height: 290px;
                            min-height: unset;
                        }
                    }
                }
            }
        }

        .package-box {
            width: 100%;
            margin: auto;
            height: auto;

            .header-btn-container {
                height: 230px;

                .header-btn {
                    padding: 13px 40px;
                }
            }
        }
    }

    .wide-search {
        width: 50% !important;
    }
}
// 575px
@media (max-width: 35.9375rem) {

    h1 {
        letter-spacing: unset;
        text-align: center;
    }

    h2 {
        text-align: center;
    }



    .action-buttons {

        .btn {
            margin: 15px 0;
        }
    }

    .approach-experience {

        .container {
            padding: 0;
        }

        .course-experience-image-container {
            margin-top: 30px;

            .course-experience-image {
                height: unset;
            }

            .course-experience-testimonial {
                padding: 15px;
            }
        }

        .row {
            margin-left: -10px;
            margin-right: -10px;
        }

        .testimonial-carousel {

            .container {

                .rec-slider-container {

                    .slide {
                        padding: 0;
                        min-height: unset;

                        .header-info {
                            margin-top: 10px;
                        }

                        .info-container {
                            min-height: 235px;
                        }

                        .testimonial {
                            padding: 15px 20px;
                            min-height: 490px;
                            &:after {
                                top: 483px;
                            }
                        }
                    }
                }
            }
        }
    }

    .banner-container {

        .banner-header {
            width: 100%;
            margin: 0;

            h1 {
                font-size: 38px;
            }
        }
    }

    .blog-post {

        h1 {
            margin-top: 20px;
            text-align: left;
        }
    }

    .blog-summary {
        font-size: 20px;
    }

    .btn-primary {
        width: 100%;
    }

    .carousel {
        &.our-approach-carousel, &.coaching-benefits-carousel {

            .header-container {
                padding: 0 5px;
            }
        }

        .container {
            padding: 0;
        }

        .header-container {
            padding: 0 5px;
        }

        .rec-slider-container {
            margin: 0;

            .slide {
                margin: 0;
            }
        }
    }

    .cart-item-container {
        border-left: none;
        border-right: none;
        border-top: none;
        padding: 0;
        border-radius: 0;
    }

    .coaching-cart-item {
        margin-left: 0;
    }

    .comment-button {
        margin: 0;
        width: 100%;
    }

    .course-hero-header {

        .course-header-row {

            .course-header-left-container {

                .course-header-left {
                    padding: 15px;
                }
            }

            .course-header-right {

                .btn-primary {
                    width: auto;
                }

                .course-top-right-info-container {

                    .button-container {

                        .btn-primary {
                            width: 100%;
                            margin: 10px auto;
                        }
                    }
                }
            }
        }
    }

    .hero-header {
        height: 705px;
    }

    .course-information-section {
        height: auto;

        .course-header-row {

            .course-top-right-info-container {

                .button-container {

                    .btn-primary {
                        width: calc(100% - 60px);
                    }
                }

                .course-information {

                    .course-top-right-section {
                        display: block;
                    }
                }
            }
        }
    }

    .course-main-section {

        .course-main-section-left {

            .image-section {
                margin: 20px 0 0;

                div {
                    width: auto;
                    float: none;
                }
            }

            .text-block-container {

                .text-block {

                    h1 {
                        letter-spacing: unset;
                    }
                }
            }
        }

        .sign-up-and-save {
            padding: 0 15px;
        }
    }

    .course-mentor-carousel {

        .header-container {

            .course-mentor-header {
                line-height: 1em;
                margin-bottom: 30px;
            }
        }

        .slide {
            height: 555px;
        }
    }

    .course-module-carousel {

        .header-container {

            .course-module-header {
                line-height: 1em;
                margin-bottom: 30px;
            }
        }

        .slide {
            height: 445px;

            .info-container {

                .header-info {
                    height: 135px;
                }
            }
        }
    }

    .course-orange-banner-section, .course-green-banner-section {

        h1 {
            margin-bottom: 25px;
        }
    }

    .course-testimonial-carousel {

        .header-container {

            .course-testimonials-header {
                line-height: 1em;
            }
        }

        .slide {

            .info-container {
                text-align: left;

                .course-testimonial-caption {
                    margin: 0 0 10px;
                }

            }

            .testimonial {
                text-align: left;
            }
        }
    }

    .featured-course {

        .green-background-header {
            text-align: center;
            margin-right: 0;
            padding: 4px;
        }

        .header-btn {
            margin: 15px auto 0;
        }
    }

    .footer-container {

        .top-footer-container {
            padding-bottom: 0;

            .top-footer {
                width: calc(100% - 40px);

                form {

                    .btn-footer-form {
                        width: unset;
                    }
                }
            }
        }
    }

    .header-details {
        padding: 15px 0;
    }

    .highlight-section-rows {

        .read-more-image-section {
            margin: 15px 0;
        }
    }

    .course-hero-header {
        height: auto;
    }

    .info-page.container {
        padding-top: 40px;
    }

    .mobile-hide {
        display: none;
    }

    .mobile-menu {

        .dropdown-menu {
            top: 33px !important;
            left: 0;
            max-width: 300px;
        }
    }

    .modal-dialog {
        right: 0;
        width: 95%;

        .modal-body {
            min-width: unset;

            .cart-item {
                height: 190px;

                img {
                    height: 90px;
                }
            }
        }
    }

    .navbar {

        .search-form {
            width: 25%;
        }

        .search-suggestions {
            left: 0;
            width: 100%;
        }
    }

    .orange-banner-section, .green-banner-section {
        padding: 30px 0 0;

        .white-btn {
            margin: 5px 0 30px;
            padding: 10px 20px;
            font-size: 15px;
        }
    }

    .organisations-header-row {
        height: 915px;

        .organisations-header-left {
            max-width: calc(100% - 30px);
            margin-bottom: 15px;
            height: 455px;
            padding: 15px;

            .header-details {
                padding: 0;

                h1 {
                    margin-bottom: 0;
                }
            }

            .text-block-container {
                margin-top: 15px;
            }
        }
    }

    .organisations-hero-header {
        height: 915px;
    }

    .organisations-testimonial-carousel {

        .rec-carousel-wrapper {
            margin-top: 30px;
            height: 955px;

            .slide {
                padding: 0;

                .info-container {
                    min-height: 325px;
                }

                .testimonial {
                    min-height: 675px;
                    &:after {
                        top: 668px
                    }
                }
            }
        }
    }

    .read-more-modal {

        .modal-dialog {
            width: 290px;
            right: 15px;
            margin: 0;

            .modal-content {

                .modal-body {

                    .left-column {

                        img {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }

    .testimonial-carousel {

        .rec-carousel-wrapper {

            .slide {
                padding: 0;

                .info-container {
                    min-height: 275px;
                }

                .testimonial {
                    min-height: 510px;
                    &:after {
                        top: 503px;
                    }
                }
            }
        }
    }

    .text-block-container {

        .page-sub-header {
            line-height: 1em;
            margin-bottom: 15px;
        }
    }

    .video-player {
        width: 290px;
        height: 163px;
    }

    .who-this-course-is-for {
        padding-bottom: 5px;
    }

    .why-us {
        padding: 40px 15px;

        .organisations-mentor-carousel {
            width: calc(100% + 30px);
            margin-left: -15px;
            height: 460px;

            .package-box {
                height: 330px;
            }

            .rec-carousel-wrapper {

                .rec-slider-container {
                    height: 330px;

                    .rec-carousel-item {

                        .slide {
                            height: 330px;
                        }
                    }
                }
            }
        }

        .package-box {

            .header-btn-container {
                height: auto;
            }
        }
    }

    .wide-search {
        width: 60% !important;
    }
}